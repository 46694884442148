import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import { Box } from '@chakra-ui/react'
import ReactFullpage from '@fullpage/react-fullpage'
import { createPopup } from '@typeform/embed'
import '@typeform/embed/build/css/popup.css'

import {
  Contact,
  Features,
  Hero,
  Layout,
  Locations,
  News,
  Partners,
  SEOMetadata,
  Testimonials,
} from '@components'

const IndexPage = ({ data }) => {
  const hero = useRef()
  const typeform = useRef()
  const [popup, setPopup] = useState()
  const { Wrapper } = ReactFullpage

  const {
    title,
    description,
    landingPageTemplate: { pageSections },
  } = data.graphCmsPage

  const { remoteTypeName: heroSectionName, ...heroData } = pageSections.find(
    section => section.remoteTypeName === 'HeroSection'
  )
  const {
    remoteTypeName: featureSectionName,
    ...featureData
  } = pageSections.find(section => section.remoteTypeName === 'FeatureSection')
  const {
    remoteTypeName: partnersSectionName,
    ...partnersData
  } = pageSections.find(section => section.remoteTypeName === 'PartnersSection')
  const {
    remoteTypeName: testimonialSectionName,
    ...testimonialData
  } = pageSections.find(
    section => section.remoteTypeName === 'TestimonialSection'
  )
  const {
    remoteTypeName: locationsSectionName,
    ...locationsData
  } = pageSections.find(
    section => section.remoteTypeName === 'LocationsSection'
  )
  const { remoteTypeName: newsSectionName, ...newsData } = pageSections.find(
    section => section.remoteTypeName === 'NewsSection'
  )
  const {
    remoteTypeName: contactSectionName,
    ...contactData
  } = pageSections.find(section => section.remoteTypeName === 'ContactSection')

  const resetHero = () => {
    // once fullPage.js has loaded, undo temporary alignment fixes
    const mediaQuery = window.matchMedia('(min-width: 1280px)')

    // reset section height for xl+ viewports
    if (mediaQuery.matches) {
      hero.current.style.height =
        'calc(100vh - clamp(6.5rem, 5.6250rem + 3.1250vw, 9.375rem))'
    }

    // reset top padding
    hero.current.style.paddingTop = '0px'
  }

  useEffect(() => {
    // configure embedded Typeform
    setPopup(
      createPopup(process.env.GATSBY_TYPEFORM_FORM_ID, {
        container: typeform.current,
      })
    )
  }, [])

  const sectionProps = {
    defaultClick: () => popup.open(),
    ml: { base: '3vw', md: '7vw', lg: '5vw' },
    mr: { base: '3vw', md: '3.5vw', lg: '2.5vw' },
  }

  return (
    <>
      <Layout>
        {/* SEO */}
        <SEOMetadata title={title} description={description} />

        {/* fullPage */}
        <ReactFullpage
          licenseKey={process.env.GATSBY_FULLPAGE_LICENSE_KEY}
          lazyLoading={false}
          menu={'.slide-menu'}
          normalScrollElements={'.react-player'}
          responsiveWidth={1279}
          paddingTop={'clamp(6.5rem, 5.6250rem + 3.1250vw, 9.375rem)'}
          verticalCentered={true}
          afterLoad={resetHero}
          render={() => (
            <Wrapper>
              <Hero ref={hero} {...heroData} {...sectionProps} />
              <Features {...featureData} {...sectionProps} />
              <Partners {...partnersData} {...sectionProps} />
              <Testimonials {...testimonialData} {...sectionProps} />
              <Locations {...locationsData} {...sectionProps} />
              <News {...newsData} {...sectionProps} />
              <Contact {...contactData} {...sectionProps} />
            </Wrapper>
          )}
        />
      </Layout>

      {/* Embedded Typeform */}
      <Box ref={typeform} />
    </>
  )
}

export const query = graphql`
  query {
    graphCmsPage(path: { eq: "/" }) {
      title
      description
      landingPageTemplate {
        ... on GraphCMS_LandingPageTemplate {
          pageSections {
            ... on GraphCMS_HeroSection {
              anchor
              title
              subtitle
              body
              buttonLabel
              buttonLink
              remoteTypeName
              statCards {
                label
                value
                plus
              }
            }
            ... on GraphCMS_FeatureSection {
              anchor
              title
              subtitle
              body
              buttonLabel
              buttonLink
              remoteTypeName
              featureCards {
                title
                body
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 100
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
              video {
                url
              }
            }
            ... on GraphCMS_PartnersSection {
              anchor
              title
              subtitle
              body
              buttonLabel
              buttonLink
              remoteTypeName
              partnerCards {
                name
                logo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 150
                        placeholder: TRACED_SVG
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
                featured
              }
            }
            ... on GraphCMS_TestimonialSection {
              anchor
              title
              subtitle
              body
              buttonLabel
              buttonLink
              remoteTypeName
              testimonialCards {
                name
                role
                company
                testimonial
                logo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 100
                        placeholder: TRACED_SVG
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
            ... on GraphCMS_LocationsSection {
              anchor
              title
              subtitle
              body
              buttonLabel
              buttonLink
              remoteTypeName
              map {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: TRACED_SVG
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            ... on GraphCMS_NewsSection {
              anchor
              title
              subtitle
              body
              buttonLabel
              buttonLink
              remoteTypeName
              newsArticles {
                headline
                date
                link
                image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 500
                        placeholder: BLURRED
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
              mediaMentions {
                name
                logo {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 100
                        placeholder: TRACED_SVG
                        formats: [AUTO, WEBP, AVIF]
                      )
                    }
                  }
                }
              }
            }
            ... on GraphCMS_ContactSection {
              anchor
              title
              subtitle
              body
              buttonLabel
              buttonLink
              remoteTypeName
            }
          }
        }
      }
    }
  }
`

export default IndexPage
